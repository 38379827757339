
import analytics from '~/mixins.js/analytics'

export default {
  mixins: [analytics],
  props: {
    href: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  }
}
