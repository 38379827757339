

import analytics from '~/mixins.js/analytics'

export default {
  mixins: [analytics],
  methods: {
    trackClick (e) {
      this.trackEvent('Search', e.target.innerText)
    }
  }
}
