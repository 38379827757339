
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import promoBanner from '../../../components/banners/promoBaner'
import analytics from '~/mixins.js/analytics'
import HeroImage from '~/components/homepage/lazy/HeroImage.vue'

extend('search-required', {
  ...required,
  message: 'Search query is required'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    promoBanner,
    HeroImage
  },
  mixins: [analytics],
  data () {
    return {
      query: '',
      loading: false
    }
  },
  methods: {
    async search () {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.loading = true
        await this.$router.push(`/s/${this.query}`)
      }
    }
  }
}
