import { diagramToAnalyticsItem } from '~/utils/helpers'

export default {
  methods: {
    trackProductClick (diagram, listName, listPosition) {
      const position = listPosition
      this.$gtag.event(
        'select_product',
        {
          content_type: 'product',
          items: {
            ...diagramToAnalyticsItem(diagram, position),
            list_name: listName
          }
        }
      )
    },
    trackEvent (category, action, label = null) {
      if (!this.$config.production) {
        console.log('trackEvent', category, action, label)
      }
      this.$matomo && this.$matomo.trackEvent(category, action, label)
      this.$gtag && this.$gtag.event(action, {
        event_category: category,
        event_label: label
      })
      this.$gtag && this.$gtag.event(
        action, {
          event_category: category,
          event_label: label,
          send_to: [this.$config.ga4TrackingId]
        }
      )
    }
  }
}
