import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=63c0cd79"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"
import style0 from "./Hero.vue?vue&type=style&index=0&id=63c0cd79&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IdSearchTypesList: require('/workspace/components/search/TypesList.vue').default,IdButton: require('/workspace/components/Button.vue').default})
